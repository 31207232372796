<template>
  <div class="equipmentStatus">
    <div class="top">
      <div class="title">
        种植面积
      </div>
    </div>
    <div class="bottom">
      <div class="item">
        <p class="name">中国<span>（万亩）</span> </p>
        <p class="num">129.44</p>
      </div>
      <div class="item">
        <p class="name">云南<span>（万亩）</span> </p>
        <p class="num">127.34</p>
      </div>
      <div class="item">
        <p class="name">保山<span>（万亩）</span> </p>
        <p class="num">13.65</p>
      </div>

      <div class="total">
        <p class="name">全球产量</p>
        <p class="num">1136.63</p>
        <p class="unit">万公顷</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "equipmentStatus",
  data() {
    return {
      list: [
        {
          name: '开机',
          num: 22,
          percentage: 34.21,
        },
        {
          name: '关机',
          num: 2,
          percentage: 68.03,
        },
        {
          name: '故障',
          num: 1,
          percentage: 34.21,
        },
        {
          name: '检修',
          num: 1,
          percentage: 34.21,
        },
      ],
      customColor: [
        {color: '#49B312'},
        {color: '#0eb3d0'},
        {color: '#BD352C'},
        {color: '#F59A23'},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.equipmentStatus {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }
  .bottom{
    height: 100%;
    width: 100%;
    padding:3%;
    background: url("@/assets/image/icon/2.png");
    background-repeat: no-repeat;
    background-size: 82% 92%;
    background-position-x: 26px;
    position: relative;

    .item{
      width: 100px;
      p{
        margin: 0;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      .name{
        font-size: 12px;
        color: rgba(184, 211, 241, 1);
        span{
          font-size: 10px;
        }
      }
      .num{
        margin-top: 3%;
        font-size: 21px;
        color: rgba(0, 234, 255, 1);
      }
    }

    .item:nth-child(1){
      position: absolute;
      top: 1%;
      right: 27%;
    }

    .item:nth-child(2){
      position: absolute;
      top: 36.5%;
      left: 5%;
    }
    .item:nth-child(3){
      position: absolute;
      top: 36.5%;
      right: 12%;
    }

    .total{
      width: 100px;
      position: absolute;
      top: 37%;
      left: 36.5%;
      p{
        margin: 0;
        display: inline-block;
        width: 100%;
        color: #ffffff;
      }
      .name{
        font-size: 12px;

      }
      .num{
        font-size: 14px;
      }
      .unit{
        font-size: 8px;
      }
    }
  }
}
</style>
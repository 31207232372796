<template>
  <div class="deviceInformation">
    <div class="top">
      <div class="title">
        产量
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <img src="@/assets/image/icon/Group.png">
      </div>
      <div class="right">
        <div class="item">
          <p><i></i> 全球 <span>1036.50</span> 万吨</p>
        </div>
        <div class="item">
          <p><i></i>中国 <span>11.51</span> 万吨</p>
        </div>
        <div class="item">
          <p><i></i>云南 <span>11.36</span> 万吨</p>
        </div>
        <div class="item">
          <p><i></i>保山 <span>2.26</span> 万吨</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "deviceInformation",
  computed: {},
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.deviceInformation {
  width: 100%;
  height: 100%;
  padding: 1.3% 0;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    width: 100%;
    height: 90%;
    display: flex;
    padding: 3% 0;

    .left {
      width: 55%;

      img {
        width: 45%;
      }
    }

    .right {
      width: 45%;
      .item p i {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 2px;
        margin-right: 2%;
      }
      .item:nth-child(1) p i {
        background: rgba(9, 109, 217, 1);
      }
      .item:nth-child(2) p i {
        background: rgba(54, 207, 201, 1);
      }
      .item:nth-child(3) p i {
        background: rgba(255, 195, 0, 1);
      }
      .item:nth-child(4) p i {
        background: rgba(165, 214, 63, 1);
      }

      .item {
        padding: 2% 0;
      }

      p {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin: 0;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);

        span {
          font-size: 20px;
          font-weight: 700;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
</style>
<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        生产情况
      </div>
    </div>
    <div class="bottom">
      <div class="row">
        <div class="row-1">
          <div class="item">
            <div class="item1">
              <el-image :src="require('@/assets/image/icon/12.png')" style="width: 100%;height: 100%"></el-image>
            </div>
            <div class="item2">
              <div class="item2-1">生豆产量</div>
              <div class="item2-2">113629.20</div>
            </div>
          </div>
          <div class="item items">
            <div class="item2">
              <div class="item2-1">种植面积</div>
              <div class="item2-2">129.44</div>
            </div>
          </div>
          <div class="item item1-2">
            <div class="item2">
              <div class="item2-1">农业产值</div>
              <div class="item2-2">35.04</div>
            </div>
            <div class="item1">
              <el-image :src="require('@/assets/image/icon/13.png')" style="width: 100%;height: 100%"></el-image>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "faultTwo",
  computed: {},
  data() {
    return {}
  },
  methods: {},
  created() {

  },

}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    width: 88%;
    height: 90%;
    padding: 0 6%;

    .row {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .row-1 {
        width: 100%;
        height: 80%;
        display: flex;
        //justify-content: space-between;
        align-items: center;
        background-image: url("@/assets/image//icon/11.png");
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 0 5%;

        .items {
          display: flex;
          justify-content: center;
        }

        .item1-2 {
          display: flex;
          justify-content: flex-end;
        }

        .item {
          width: 50%;
          margin: 0 auto;
          display: flex;

          .item1 {
            width: 25%;
            height: 100%;
          }

          .item2 {
            width: 50%;
            height: 100%;

            .item2-1 {
              font-size: 14px;
              font-weight: 400;
              color: rgba(73, 146, 255, 1);
              line-height: 28.96px;
            }

            .item2-2 {
              font-size: 20px;
              font-weight: 700;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        国内咖啡消费变化趋势
      </div>
    </div>
    <div class="bottom">
      <div id="echartsLine" style="width: 100%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faultReporting",
  computed: {},
  data() {
    return {
      colorList: [
        ['#e10d33', '#e10d33', '#e10d33'],
        ['#f54e1b', '#f54e1b'],
      ],
      option: {
        color: ['rgba(63, 145, 216, 1)', '#f54e1b'],
        title: {},
        legend: {
          bottom: '5%',
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => (value ? value + '万吨' : '-'),
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '20%',
          top: '20%',
          containLabel: true,
        },
        dataset: {
          dimensions: [],
          source: [],
        },
        xAxis: {
          name: '',
          type: 'category',
          data: ['2017年', '2018年', '2019年', '2020年', '2021年', '2022年'],
          axisLabel: {
            textStyle: {
              color: 'rgba(161, 200, 255, 1)',
              fontSize: 8,
            },
          },
        },
        yAxis: {
          name: '消费量(万吨)',
          minInterval: 2,
          axisLabel: {
            textStyle: {
              color: 'rgba(161, 200, 255, 1)',
              fontSize: 8,
            },
          },
        },
        series: [
          {
            data: [19.31, 18.51, 18, 21.6, 25.2, 28.8],
            type: 'line',
            lineStyle: {
              color: 'rgba(63, 145, 216, 1)' //改变折线颜色
            },
            itemStyle: {
              normal: {
                areaStyle: {type: 'default'},
                color: {
                  type: 'linear',
                  x: 0, //右
                  y: 0, //下
                  x2: 0, //左
                  y2: 1, //上
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(10, 71, 118, 0.71)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(10, 71, 118, 0)' // 100% 处的颜色
                    }
                  ],
                }
              }
            },
          }
        ],
      },
    }
  },
  methods: {

    getEcharts() {
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('echartsLine'));
        myChart.setOption(this.option);
        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            myChart.resize();
          }, 100)
        })
      })
    },
  },
  created() {
    this.getEcharts()
  },

}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    width: 100%;
    height: 90%;
  }
}
</style>

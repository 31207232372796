<template>
  <div class="processProgress">
    <div class="tabbar">
      <div class="item" v-for="(item,index) in tab" :key="index" @click="getTab(index)">
        <p :class="index==tabIndex?'atv':''"><span></span> {{ item }} <span></span></p>
      </div>
    </div>
    <div class="map">
      <img v-if="tabIndex==0" src="@/assets/image/map/01.png" alt="">
      <img v-if="tabIndex==1" src="@/assets/image/map/02.png" alt="">
      <img v-if="tabIndex==2" src="@/assets/image/map/03.png" alt="">

    </div>
  </div>
</template>

<script>
export default {
  name: "processProgress",
  data() {
    return {
      tabIndex: 1,
      tab: ['全球', '中国', '云南']
    }
  },
  methods: {
    getTab(index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="scss">
.processProgress {
  .tabbar {
    padding: 0 10%;
    width: 80%;
    display: flex;
    justify-content: space-around;

    .item {
      width: 20%;

      p {
        font-size: 21px;
        color: rgba(10, 122, 207, 1);
        font-weight: 700;
        margin: 0;
        box-shadow: inset 0 -20px 10px -10px rgba(63, 145, 216, 0.5);
        line-height: 34px;
      }

      .atv {
        color: #ffffff;
      }
    }

    span {
      display: inline-block;
      width: 5px;
      height: 5px;
      background: rgba(234, 179, 9, 1);
      border-radius: 5px;
      margin: 0 3%;
    }
  }

  .map {
    padding-top: 10%;
    padding-bottom: 10%;
    img {
      width: 100%;
    }
  }
}

</style>
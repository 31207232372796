<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        国内咖啡生产情况
      </div>
    </div>
    <div class="bottom">
      <div class="list">
        <div class="item">
          <div class="box">
            <img src="@/assets/image/icon/06.png">
          </div>
          <p class="num">129.44</p>
          <p>种植总面积 <span>（万亩）</span></p>
        </div>

        <div class="item">
          <div class="box">
            <img src="@/assets/image/icon/07.png">
          </div>
          <p class="num">35.04</p>
          <p>农业产值 <span>（万元）</span></p>
        </div>

        <div class="item">
          <div class="box">
            <img src="@/assets/image/icon/08.png">
          </div>
          <p class="num">113629.20</p>
          <p>生豆总产量 <span>（吨）</span></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "faultReporting",
  computed: {},
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.faultReporting {
  padding: 3.89% 0;
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -30px;
      left: -5px;
    }
  }

  .bottom {
    padding:3% 0;
    .list {
      display: flex;

      .item {
        width: 33.3%;

        .box {
            img{
              width: 25%;
            }
        }
        p{
          margin: 0;
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
          span{
            font-size: 12px;
          }
        }
        .num{
          font-weight:700;
          font-size: 21px;
        }
      }
    }
  }
}
</style>
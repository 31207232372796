<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        农业产值
      </div>
    </div>
    <div class="bottom">
      <div class="list">
        <div class="item">
          <div class="box">
            <img src="@/assets/image/icon/03.png">
            <div class="text">
              <p>435.61</p>
              <span>亿美元</span>
            </div>
          </div>
          <p>全球咖啡农业产值</p>
        </div>

        <div class="item">
          <div class="box">
            <img src="@/assets/image/icon/04.png">
            <div class="text">
              <p>35.04</p>
              <span>亿美元</span>
            </div>
          </div>
          <p>中国咖啡农业产值</p>
        </div>

        <div class="item">
          <div class="box">
            <img src="@/assets/image/icon/05.png">
            <div class="text">
              <p>34.48</p>
              <span>亿美元</span>
            </div>
          </div>
          <p>云南咖啡农业产值</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "faultReporting",
  computed: {},
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    .list {
      display: flex;

      .item {
        width: 33.3%;

        .box {
          position: relative;

          img {
            width: 100%;
          }

          .text {
            position: absolute;
            top: 45%;
            width: 100%;
            p {
              margin: 0;
              font-size: 20px;
              color: rgba(0, 234, 255, 1);
              font-weight: 400;
            }
            span{
              font-size: 10px;
              color: rgba(0, 234, 255, 1);
            }
          }
        }
        p{
          margin: 0;
          font-size: 14px;
          color: rgba(184, 211, 241, 1);
        }
      }
    }
  }
}
</style>
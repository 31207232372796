<template>
  <div class="dataBoardIndex">

    <div class="up">
      <div class="btn" @click="open">服务</div>
    </div>

    <div class="top">
      <div class="top_1">
        <div class="title">
          咖啡数字化产业数据大屏
        </div>
      </div>
    </div>
    <div class="bottom">
      <!--      左-->
      <div class="left">
        <div class="publicTitle">
          咖啡产业数据
        </div>
        <div class="left_1 borderBg">
          <equipment-status/>
        </div>
        <div class="left_2 borderBg">
          <device-information/>
        </div>
        <div class="left_3 borderBg">
          <fault-reporting/>
        </div>

<!--        <div class="publicTitle">-->
<!--          国内咖啡产业生产情况数据-->
<!--        </div>-->
        <div class="left_4 borderBg">
          <produ-ction></produ-ction>
        </div>
      </div>

      <!--      中-->
      <div class="center">
        <div class="center_2">
          <process-progress/>
        </div>
        <div class="center_3 borderBg">
          <production-progress/>
        </div>
      </div>

      <!--      右-->
      <div class="right">
        <div class="right_1 borderBg">
          <work-order-status/>
        </div>
        <div class="publicTitle">
          云南咖啡产业概况
        </div>
        <div class="right_2 borderBg">
          <work-report/>
        </div>
        <div class="publicTitle">
          保山咖啡产业概况
        </div>

        <div class="right_3 borderBg">
          <industry></industry>
        </div>
        <div class="right_4 borderBg">
          <rejects-analyse/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EquipmentStatus from "@/views/data-board/left/equipmentStatus.vue";
import DeviceInformation from "@/views/data-board/left/deviceInformation.vue";
import FaultReporting from "@/views/data-board/left/faultReporting.vue";
import ProduCtion from "@/views/data-board/left/production.vue";
import ProcessProgress from "@/views/data-board/center/processProgress.vue";
import ProductionProgress from "@/views/data-board/center/productionProgress.vue";
import WorkOrderStatus from "@/views/data-board/right/workOrderStatus.vue";
import WorkReport from "@/views/data-board/right/workReport.vue";
import industry from "@/views/data-board/right/industry.vue";
import RejectsAnalyse from "@/views/data-board/right/rejectsAnalyse.vue";

export default {
  name: "dataBoardIndex",
  components: {
    RejectsAnalyse,
    WorkReport,
    WorkOrderStatus,
    ProductionProgress,
    ProcessProgress,
    industry,
    FaultReporting, DeviceInformation, EquipmentStatus,ProduCtion
  },

  methods:{
    open() {
      window.location.href = 'https://kcdp.bsmyit.com'
    },
  }
}
</script>

<style scoped lang="scss">
.dataBoardIndex{
  background: #000;
}

//公共标题
.publicTitle{
  background: url("@/assets/image/icon/1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  margin: 0.6% 0;
  text-align: left;
  padding-left: 14%;
  font-size: 21px;
  line-height: 44px;

}
.dataBoardIndex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;



  .up {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 0;
    top: 45%;

    .btn {
      width: 100%;
      height: 100%;
      border-radius: 50% 0 0 50%;
      //background-color: rgba(194,108,21,0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      background: linear-gradient(to right, rgba(194,108,21,0.8), rgba(194,108,21,0.5), rgba(194,108,21,0.1));
    }
  }

  .top {
    width: 100%;
    height: 5%;
    background: #040a32;
    padding-top: 0.5%;

    .top_1 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("@/assets/image/topBg.png");
      background-size: 100% 100%;
    }

    .title {
      font-size: 2.3rem;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .bottom {
    height: 95vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: url("@/assets/image/banner1.gif");
    background-repeat: no-repeat;
    background-size: 85% 85%;
    background-position: 50% 0%;

    .borderBg {
      background: url("@/assets/image/item.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 2.86% 0 !important;
    }

    .left {
      width: 27%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 1%;
      margin-right: 1%;

      .left_1 {
        //flex: 1;
        width: 100%;
        height: 25%;
        margin-top: 2%;
        position: relative;

        .left_1_1 {
          position: absolute;
          height: 20%;
          border: 1px solid rgba(2, 166, 181, 0.55);
        }
      }

      .left_2 {
        width: 100%;
        margin-top: 2%;
      }

      .left_3 {
        width: 100%;
        margin-top: 2%;
      }
    }

    .center {
      width: 46%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .center_1 {
        flex: 1;
        margin-top: 2%;
        display: flex;
        flex-direction: row;


        .center_1_1 {
          flex: 1;
          width: 30%;
        }
      }

      .center_2{
        height: 73.5vh
      }
    }

    .right {
      width: 27%;
      height: 100%;
      //flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 1%;
      margin-left: 1%;

      .right_1 {
        height: 240px;
        margin-top: 2%;
      }

      .right_2 {
        height: 190px;
        margin-top: 2%;
      }

      .right_3 {
        height: 190px;
        margin-top: 2%;
        margin-bottom: 2%;
      }
    }
  }
}
</style>
<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        赛事资讯
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_1">
        <div class="bottom_title">
          <div class="title_item">赛事名称</div>
          <div class="title_item">时间</div>
          <div class="title_item">地点</div>
        </div>
        <vue-seamless-scroll :data="listData" class="warp" :class-option="defaultOption">
          <ul class="item">
            <li v-for="(item, index) in listData" :key="index">
              <span class="date">{{item.name}}</span>
              <span class="faultType">{{item.date}}</span>
              <span class="equipmentType">{{item.address}}</span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faultReporting",
  computed: {
    defaultOption () {
      return {
        step: 0.15, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  data() {
    return {
      listData: [
        {
          name:'WRTC2024世界咖啡烘焙团体赛',
          date:'2024年5月10日-5月12日',
          address:'广州'
        },
        {
          name:'2024 5TH第五届《智烘杯》烘焙比赛',
          date:'2024年5月15日-5月18日',
          address:'上海'
        },
        {
          name:' 2024 5TH第五届《智烘杯》烘焙比赛',
          date:'2024年5月28日-5月31日',
          address:'广州'
        },
        {
          name:'WRTC2024世界咖啡烘焙团体赛',
          date:'2024年5月31日-6月2日',
          address:'武汉'
        },
        {
          name:'2024云南咖啡生豆大赛',
          date:'2024年5月10日-6月2日',
          address:'云南保山'
        },
        {
          name:'2024PCA专业咖啡大师竞技赛',
          date:'2024年6月14日-6月16日',
          address:'山西太原'
        },

        {
          name:'2024 5TH第五届《智烘杯》烘焙比赛',
          date:'2024年6月18日-6月21日',
          address:'邯郸'
        },
        {
          name:'2024第十届中国咖啡冲煮大赛',
          date:'2024年6月22日-6月23日',
          address:'大连'
        },
        {
          name:'2024云南咖啡烘焙夏季赛',
          date:'2024年6月26日',
          address:'云南保山'
        },

        {
          name:'2024第十届中国咖啡冲煮大赛',
          date:'2024年7月6日',
          address:'杭州'
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 23px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom_1 {
      margin-top: 2%;
      width: 90%;
      height: 90%;

      .bottom_title {
        width: 100%;
        height: 10%;
        display: flex;
        justify-items: center;
        align-items: center;

        .title_item {
          flex: 1;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          background: rgba(250,250,250,0.5);
          color: rgba(8, 11, 189, 1);
          padding: 1% 0;
        }
      }

      .warp {
        height: 111px;
        width: 100%;
        margin-top: 2%;
        overflow: hidden;
        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;
          li{
            display: block;
            height: 35px;
            line-height: 35px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #fff;

            span {
              flex: 1;
              overflow:hidden;
              white-space: nowrap;
              text-overflow:ellipsis;
            }

            span:hover{
              text-overflow:inherit;
              overflow: visible;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="productionProgress">
    <div class="top">
      <div class="title">
        云南精品庄园
      </div>
    </div>
    <div class="bottom">
      <div class="item">
        <el-image class="img" :src="require('../../../assets/image/shop/1.jpg')"></el-image>
        <p>保山比顿咖啡有限公司</p>
      </div>
      <div class="item">
        <el-image class="img" fit="fit" :src="require('../../../assets/image/shop/2.jpg')"></el-image>
        <p>保山市高老庄农副产品开发有限公司</p>
      </div>
      <div class="item">
        <el-image class="img" fit="fit" :src="require('../../../assets/image/shop/03.jpg')"></el-image>
        <p>保山市新寨咖啡有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productionProgress",
  data() {
    return {
      imgList: [
        'https://placekitten.com/300/150', 'https://placekitten.com/300/150', 'https://placekitten.com/300/150',
        'https://placekitten.com/300/150'
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.productionProgress {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;

    .item {
      width: 28%;
      position: relative;
      margin: 1%;
      .img {
        width: 100%;
        height: 138px;
      }
      p {
        position: absolute;
        bottom: 0px;
        left: 0px;
        background: rgba(0,0,0,0.5);
        display: inline-block;
        width: 100%;
        color: #ffffff;
        font-size: 14px;
        padding: 2% 0;
        margin: 0;
      }
    }
  }
}
</style>



<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">

      </div>
    </div>
    <div class="bottom">
      <div class="row">
        <div class="row-1">
          <div class="item">
            <el-image :src="require('@/assets/image/icon/14.png')" style="width: 70%;height: 60%"></el-image>
            <div class="item-1">全市小粒咖啡种植面积</div>
            <div class="item-2">13万亩</div>
          </div>
        </div>
        <div class="row-2">
          <div class="item2" v-for="(item,index) in list" :key="index">
            <div class="item2-1">{{ item.name }}</div>
            <div class="item2-2">
              <div>{{ item.value }}</div>
              <div class="text">
                {{ index == 0 ? '万吨' : index == 1 ? '亿元' : index == 2 ? '户' : index == 3 ? '万余人' : index == 4 ? '个' : '户' }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "faultTwo",
  computed: {},
  data() {
    return {
      list: [
        {
          name: '咖啡产量',
          value: '2.26',
        },
        {
          name: '产业链产值超过',
          value: '2.26',
        },
        {
          name: '咖啡加工产业',
          value: '52',
        },
        {
          name: '带动咖农',
          value: '5',
        },
        {
          name: '咖啡专业合作社',
          value: '88',
        },
        {
          name: '咖啡家庭农场',
          value: '6',
        },
      ],
    }
  },
  methods: {},
  created() {

  },

}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;

    .title {
      width: 100%;
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      position: relative;
      top: -13px;
      left: -5px;
    }
  }

  .bottom {
    width: 100%;
    height: 90%;

    .row {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .row-1 {
        width: 30%;
        height: 100%;

        .item {
          width: 100%;
          height: 100%;

          .item-1 {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20.27px;
            color: rgba(255, 255, 255, 1);
          }

          .item-2 {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 34.75px;
            color: rgba(0, 234, 255, 1);

          }
        }
      }

      .row-2 {
        width: 60%;
        height: 60%;
        margin-left: 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .item2 {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 4%;

          .item2-1 {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 17.38px;
            color: rgba(255, 255, 255, 1);
          }

          .item2-2 {
            display: flex;
            align-items: flex-end;
            font-size: 21px;
            font-weight: 700;
            line-height: 25px;
            color: rgba(0, 234, 255, 1);

            .text {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
      }

    }
  }
}
</style>

import VueRouter from "vue-router"
import DataBoard from "@/views/data-board/index.vue";
const router=new VueRouter({
    routes:[
        //配置路由的路径
        {
            path:'/',
            component:DataBoard
        },
    ]
})
export default router;